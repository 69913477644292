import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { remove } from 'jszip';
import { InspectionType } from 'src/app/entities/inspections/complete-inspection';

export const en = {
  tire: {
    name: 'Tires',
    position: 'Position',
    makeModel: 'Make/Model',
    year: 'Year',
    dimension: 'Dimension',
    season: 'Season',
    treadDepth: 'Tread Depth',
    replacement: ': Replacement needed immediately',
    replacementSoon: ': Replacement needed soon',
    replacementNotSoon: ': No replacement needed',
  },
  settings: {
    invalidPhoneNumber: 'Invalid Phone Number',
    invalidEmailAddress: 'Invalid Email Address',
    update: 'Update',
    cancelChanges: 'Cancel Changes',
    email: 'Email Address',
    phoneNumber: 'Phone Number',
    companyContactInfo: {
      title: 'Company Contact Information',
      companyName: 'Company Name',
      companyAddress: 'Company Address',
      companyPhoneNumber: 'Company Phone Number',
    },
    billingContactInfo: {
      title: 'Billing Contact Information',
      address: 'Billing Address',
    },
    repContactInfo: {
      title: 'Representative Contact Information',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email Address (Will be used for the inspection reports)',
    },
  },
  general: {
    or: 'or',
    email: 'Email',
    password: 'Password',
    confirmation: 'Confirmation',
    disconnect: 'Disconnect',
    section: 'Section',
    administration: 'Administration',
    save: 'Save',
    add: 'Add',
    name: 'Name',
    to: 'to',
    above: 'and above',
    from: 'From',
    cancel: 'Cancel',
    close: 'Close',
    greetings: 'Hi',
    saveAndSubmit: 'Save and Submit',
    saveMessage: 'Do you want to submit and send changes to the client?',
    'up-to-date': 'You are up to date! 🎉',
    done: 'done',
    next: 'Next',
    'return-to-site': 'Return to site',
    other: 'Other',
    delete: 'Delete',
    generic_delete_question: 'Are you sure you want to delete this?',
    generic_unassign_question: 'Are you sure you want to unnasign this?',
    letsgo: 'Continue',
    download_link: 'Download link',
    intro_message: "It's a go! Welcome to your customized Dashboard 😉",
    good: 'I like it!',
    bad: 'Not for me!',
    ok: 'Alright!',
    finalizeBooking: 'Finalize the booking',
    confirm: 'Confirm',
    back: 'Back',
    previous: 'Previous',
    welcome: 'Welcome! 🙏',
    saved: 'Saved!',
    'read-more': 'Read more',
    display_name_message: "What's your name?",
    here_you_go: 'Here you go!',
    a_cool_name: 'Your cool name',
    search: 'Search',
    next_step: 'Next Step',
    alright: 'Alright!',
    warning: 'Attention',
    continue: 'Continue',
    selected: 'Selected',
    unassign: 'Unassign',
    yes: 'Yes',
    no: 'No',
    firstName: 'First Name',
    lastName: 'Last Name',
    pay: 'Pay',
    help: 'Need help?',
    lets_go: "Let's go!",
    welcome_person: 'Welcome',
    waiting_steps: 'Here are the steps that await you:',
    your_needs: 'Indicate your needs',
    your_fav_vehicules: 'Choose your favorite vehicles',
    search_best: 'Search and receive the best ads',
    all_ressources:
      'Access all CarInspect resources to buy with confidence (inspections, insurance, guarantees, purchase contract between individuals, guides...)',
    logout: 'Logout',
    loadMore: 'Scroll down to load more vehicles.',
    report: 'Report',
  },
  firebase: {
    VERIFY_EMAIL: {
      title: 'Email validation',
      content: 'Your email was validated successfully!',
    },
    PASSWORD_RESET: {
      title: 'Password reset',
      content: 'Fill the form bellow to reset your password 😉',
    },
  },
  errors: {
    disconnect: 'You have been disconnected for security reasons.',
    forbidden: "You don't have access to that ressource.",
    'missing-infos': 'Please fill the required fields.',
    'wrong-password': 'Your email/password is invalid.',
    'user-disabled': 'You account has been disabled.',
    'page-not-found': {
      title: 'Page not found',
      body: 'We cannot find the page you are looking for. You can still use our tools!',
    },
    redirect: {
      website: 'Visit our website',
      search: 'Find my car',
      inspection: 'Book an inspection',
    },
    'not-found': 'The ressource you are looking for cannot be found.',
    'invalid-email': 'The email is invalid.',
    'weak-password': 'Your password is weak.',
    'email-already-in-use': 'Your account already exists',
    error: 'An error occured',
  },
  authentication: {
    policies:
      "By continuing, you're accepting our <a href='https://carinspect.com/conditions-of-use/' target='_blank'>Terms of service</a>",
    connect: 'Connect',
    connect_long: 'Already have an account? <a>Login</a>',
    signup: 'Signup',
    signup_long: "Don't have an account? <a>Register</a>",
    tagline:
      "Buying a used car has never been that <span class='overline'>simple.</span>",
    tagline_signup:
      "Your <span class='overline'>trusted</span> partner to buy your used car",
    login: 'Login',
    'login-b2b': 'Corporate Login',
    'login-page': {
      connect_with_google: 'Connect with Google',
      connect_with_facebook: 'Continue with Facebook',
    },
    'signup-page': {
      signup_with_google: 'Signup with Google',
      signup_with_facebook: 'Continue with Facebook',
      password_confirmation: 'Confirmation',
    },
    confirmation: 'Please verify your email address',
    'confirmation-page': {
      title: 'Please verify your email address',
      'resend-code': 'Send the confirmation link again.',
      message:
        'A link has been sent to your email address. Use it to verifiy your account.',
    },
    forgot_password: 'I forgot my password',
    password_modal: {
      title: 'Reset my password',
      confirm: 'Reset',
      email: 'Email',
      message: 'A reset link has been sent to your email!',
    },
    password_reset_successful: 'Your password has been successfully reset!',
    new_code_sent: 'A new code has been sent',
    B2B_forgot_password: 'Forgot your password?',
    B2B_contact: 'Contact us at',
  },
  client: {
    holiday: 'Happy Holidays!',
    holiday_description:
      'Please note that our service is unavailable until January 2, 2024, inclusively.',
    form: {
      otherSpecification: 'Please clarify your answer:',
      defectSpecification: 'Please specify the defect:',
      recommendation: 'Recommendations: ',
      estimated_cost: 'Estimated cost: ',
    },
    purchased_content:
      '<b>Congrats 🎉</b> <br> Buying a vehicle is a big adventure! Thanks for your trust ! 🍋',
    suggestions: {
      needs: 'According to your needs, here are the cars we suggest to you:',
      favorites: 'My favorite vehicles list',
      important:
        "Add favorite vehicles to this list so that we can search for the best deals currently on the market. Don't panic: you can change and complete this list with other car models in the next step",
      add_car: 'Add a vehicule',
    },
    insurance: {
      title: 'Insurance',
    },
    pro: {
      title: 'To have a access to our pros, you have to be pro!',
      message:
        "In order to benefit from all the content, simply continue your process and pay the fees. We'll help you find the best car for your needs.",
    },
    menu: {
      CarInspect: 'My CarInspect',
      guides: 'My Guides',
      general: 'Home',
      admin: 'ADMIN DASHBOARD',
      sequence: 'My Adventure',
      insurance: 'My Insurance',
      preferences: 'My preferences',
      favorites: 'My selection',
      profile: 'Profile',
    },
    informations: {
      get_your_kiwiz: 'Get your CarInspect ! 🎉',
      get_kiwiz_message:
        'A CarInspect expert is currently looking for ads the corresponds to your needs! 😉',
      get_kiwiz_message_ads:
        'A CarInspect expert has found ads for you! Take a look!',
      see_my_kiwiz: 'See my CarInspect',
      continue_my_sequence: 'Continue my process',
    },
    guides: {
      title: 'You guides',
      no_guides: 'There are no guides available at this moment.',
      consult: 'Read article',
      download_pdf: 'Download pdf',
    },
    preferences: {
      modify_criteria:
        'Modify your search criteria at any time to get the best possible ads!',
      consideration:
        'These will be taken into consideration in the next search for advertisements that you launch.',
      vehicle: 'Vehicle 🚗',
      max_price: 'Maximum price',
      max_mileage: 'Maximum mileage',
      min_year: 'Minimum year',
      max_vehicle_age: 'Maximum age',
      body_work: 'Bodywork type',
      seller: 'Seller',
      search: 'Search',
      postal_code: 'Postal Code',
      manual: 'Manual',
      automatic: 'Automatic',
      no_preference: 'No preferences',
      motor_skills: 'Motor skills',
      traction_integral: 'All wheel drive',
      annual_mileage: 'Average annual mileage',
      my_priorities: 'My priorities',
      criteria: 'Criteria',
    },
    model_algo: {
      title_part: {
        title: 'Model Suggestions',
        welcome_message: 'Welcome to the Model Suggestion Page',
        instructions:
          'Choose your criteria and we will suggest to you the best vehicles!',
        ad_encouragement:
          'You can consider those in your next vehicle ad search.',
        dont_fill_all: "* Psst! You don't have to fill everything",
        return_website: 'Return to website',
      },
      typesList: {
        sedan: 'Sedan',
        convertible: 'Convertible',
        pickup: 'Pick-up',
        van: 'Van',
        coupe: 'Coupe',
        hatchback: 'Hatchback',
        minivan: 'Minivan',
        wagon: 'Wagon',
        suv: 'SUV',
        roadster: 'Roadster',
        no_preference: 'No preference',
      },
      priorities: 'Priorities',
      prioritiesList: {
        fuel_consumption: 'Low consumption',
        driving: 'Fun factor',
        interior: 'Comfort',
        reliability: 'Reliability',
        safety: 'Security',
      },
      requirement: 'Requirements',
      equipment: 'Essential Equipment',
      equipmentList: {
        sunroof: 'Sunroof',
        heated_front_seats: 'Heated front Seats',
        gps: 'GPS',
        aux: 'Auxiliary audio',
        cd_player: 'CD player',
        apple_carplay: 'Apple CarPlay compatibility',
        lane_departure_warning: 'Lane departure warning',
        siriusxm: 'Sirius XM',
        bluetooth: 'Audio Bluetooth',
        remote_engine_start: 'Remote engine start',
        'adaptive cruise control': 'Adaptive cruise control',
        forward_collision_warning: 'Forward collision warning',
        emergency_braking: 'Emergency braking',
        usbs: 'Front USB ports',
        bindspot_detection: 'Blindspot detection',
        no_preference: 'No preference',
      },
      autonomy: 'Battery life (km)',
      min_year: 'Minimum year',
      max_year: 'Maximum year',
      min_passengers: 'Minimum number of passengers',
      engine: 'Engine',
      engineList: {
        electric: 'Electric',
        hybrid: 'Hybrid',
        gas: 'Gas',
        no_preference: 'No preference',
      },
      transmissionsList: {
        manuel: 'Manual',
        automatic: 'Automatic',
      },
      drivetrain: 'Drivetrain',
      drivetrainList: {
        traction: 'Traction',
        propulsion: 'Propulsion',
        integral: 'All-wheel drive',
        no_preference: 'No preference',
      },
      fuel_type: 'Fuel Type',
      fuelList: {
        regular: 'Regular',
        regular_e85: 'Regular E85',
        super: 'Super',
        diesel: 'Diesel',
        premium: 'Premium',
        no_preference: 'No preference',
      },
      your_budget: 'Your budget',
      result_box: {
        results: 'Results',
        description: 'Click here to see your futur potential CarInspect! 🥝',
        button: 'Click here to see results',
        no_results: 'Sorry, no result corresponds to your criteria',
      },
      results: {
        make: 'MAKE',
        model: 'MODEL',
        year: 'YEAR',
        score: 'SCORE',
        see_car: 'See the vehicle',
      },
    },
    ads: {
      title: 'Your CarInspect ads',
      see_ad: 'View ad',
      back: 'Back to my ads',
      vehicle_description: 'Vehicle Description',
      expert_note: 'Expert Note',
      client_note: 'Personal Notes',
      drivetrain: 'Drivetrain',
      fuel: 'Fuel',
      consumption: 'Fuel consumption',
      maintenance: 'Maintenance',
      purchased: 'I bought it!',
      view_on_site: 'View ad 🔗',
      vehicle_informations: 'Vehicle Information',
      make: 'Make',
      model: 'Model',
      year: 'Year',
      version: 'Version',
      mileage: 'Mileage',
      price: 'Price',
      recommended_drivetrain: 'Recommended Drivetrain',
      ratings: 'CarInspect expert ratings',
      pricing: 'Detailed Pricing',
      taxes: 'Taxes',
      warranty: 'Warranties 36months/60000km',
      licenses: 'Licenses',
      insurance: 'Insurance',
      buying_budget: 'Purchase cost',
      yearly_budget: 'Annual cost',
      show_warranty: 'Include warranties',
      inspect: 'Inspect',
      my_kiwiz: 'My CarInspect',
      search_kiwiz: 'The CarInspect fishing was good! 🥝🚘',
      research_all_platforms:
        'We took your favorites, preferences and criteria into consideration, and we searched all platforms for the best deals currently on the market (our technology only allows you to select cars at an attractive price 💡)',
      discover_announcement:
        'Discover the ads found, and hurry, they will no longer be available soon! 🌟✅',
      hope_its_kiwiz: 'On espère que ce sera un kiwiz ! 🤞',
      kiwiz_ideal:
        '(As a reminder, a kiwiz is an ideal used car with no hidden flaws, bought with confidence! 🙌)',
      next_step: 'Next steps:',
      call_owner: 'Call the owner',
      guide_available: '(guide available in the "Resources" tab)',
      do: 'Do a',
      mecanical_inspection: 'mechanical inspection',
      assure_its_kiwiz:
        'to make sure it\'s a kiwiz and not a lemon ... ("Inspections tab',
      continue: ' Continue',
      delete_announcement: 'Delete announcement',
      announcement_of: 'Announcement of ',
      kiwiz_ideal_meskiwiz:
        'As a reminder, a kiwiz is an ideal used car with no hidden flaws, bought with confidence! 🙌',
      selected_announcement:
        'Find below your ads selected and classified according to your favorite models.🚘',
      my_models: 'My models',
      fav_model:
        'To receive the best ads available on the market, you must first inform us of your 3 favorite models on the page',
      general: '"Home" ',
      search_best_announcement: 'and click on "Find the best ads".',
      best_deals: 'Then we would go in search of the best deals.🚘',
      add_favorite: 'Add',
      remove_favorite: 'Remove',
      kiwiz_opinion: "CarInspect's opinion",
      technical_sheet: 'Technical sheet',
      price_and_kilometers: 'Average prices and mileage (per year)',
      average_price: 'Average price',
      average_kilometers: 'Average kilometers',
      remove_from_favorites: 'Remove from favorites',
      see_form: 'See the form',
      submission_here: '(make your submission here)',
    },
    inspections: {
      title: 'Book an Inspection',
      insurance_inspection: 'Insurance inspection',
      complete_inspection: 'Complete inspection',
      pick_inspection: 'Choose the inspection you want to perform below.',
      inspection_carried_out: 'Inspections carried out',
      find_under_inspection_carried_out:
        'Find below the inspections you carried out',
      Voiture: 'Car',

      steps: {
        header: 'The CarInspect inspection, in 4 simple steps',
        first_step: '1- Subscribe to the inspection here ',
        first_desc:
          'The amount of the inspection will be taken from you in order to reserve it. If for some reason the inspection cannot take place, you will be reimbursed in full.',
        second_step:
          '2- CarInspect will organize a meeting between the seller and the CarInspect inspector as soon as possible',
        second_desc: 'You have nothing to do 🤩',
        third_step:
          '3- Inspector CarInspect moves and inspects the vehicle rigorously, on 170 points',
        third_desc:
          'You don\'t even have to be with him on site! If you choose the "Complete" inspection, Inspector CarInspect will negotiate the vehicle for you, depending on the condition.',
        fourth_step:
          '4- You will receive the digital inspection report which tells you if the inspector considers that the car is a good deal or not',
        fourth_desc: 'Goodbye scams 💣🙌',
      },
      question_mark: "Don't know which inspection to choose?",
      find_out_more: 'Find out more',
      tabs: {
        inspections_report: 'My reports',
        subscribe: 'Subscribe',
        no_report: "You don't have an inspection report yet",
      },
      mandate_page: {
        mandate: 'Mandates',
        make_model_year: 'Make/Model/Year',
        informations: 'Informations',
        url: 'Seller contact details',
        postal_code: 'Postal code',
        status: 'Status',
        available: 'Available',
        confirmed: 'Confirmed',
        accepted: 'Accepted',
        started: 'Started',
        finished: 'Completed',
        accept: 'Accept',
        confirm: 'Confirm',
        remove: 'Cancel',
        made_on: 'made on ',
        debut: 'Start the inspection',
        continue: 'Continue inspection',
        completedInspection: 'Inspection completed',
        date: 'Date of inspection',
        changeDate: 'Change inspection date',
        mandate_inspect: 'Inspection mandate',
        Huile: 'Oil test',
        PreAchat: 'Standard inspection',
        ComposantSpecifique: 'Inspection of a specific component',
        communicate: 'Communicate with the seller !',
        typeInspection: 'Inspection type',
        time_and_date:
          'Find a date and time when the inspection can be performed.',
        enter_date: 'Enter the date of the inspection',
        enter_time: 'Enter the time of the inspection',
        open: 'Show details',
        reportPb: 'Problem ?',
        issue: 'Technical issue? Start a new inspection from here!',
        confirmDate: 'Confirm inspection date?',
        infoModal: {
          title: 'Informations',
          inspectionDate: 'Inspection scheduled for ',
          sellerInfo: 'Seller',
          contactInfo: 'Contact details: ',
          adCity: 'City: ',
          customerInfo: 'Customer',
          lastName: 'Last name: ',
          firstName: 'First name: ',
          customerPhone: 'Phone number: ',
          kiwizAdminNumber: 'CarInspect contact (Ludwig): ',
          negoRequest: 'Negotiation request: ',
          extraRequest: 'Extra request: ',
          wantInspectorCall:
            'This customer wants to be called after the inspection.',
          callNotNecessary:
            "This customer doesn't want to be called after the inspection.",
          goToUrl: 'See ad',
        },
        reportModal: {
          title: 'Report a problem',
          noResponse: 'Seller do not response',
          contactLater: 'Seller will contact me later',
          rescheduleInspection: 'Seller wants to reschedule the inspection',
          other: 'Other',
          alreadySold: 'The car is already sold',
          sellerRefusal: 'Seller refuses to have the car inspected',
          back: 'Back',
          otherInput: 'Specify the problem you face, please',
          confirm: 'Confirm',
        },
        badDateModal: {
          title: 'Invalid date',
          message:
            'You must set a date that is greater than the current date and time.',
        },
        modifiedDateReason: {
          title: "Postponement's reason",
          meteo: 'Weather condition',
          buyer: "Buyer's request",
          seller: "Seller's request",
          inspector: "Inspector's request",
          other: 'Other',
        },
        newInspectionTypeModal: {
          title: 'What type of inspection do you want to create?',
          iA: 'Insurance',
          complete: 'Regular',
        },
      },
      inspector_profile: {
        email: 'Email: ',
        description: 'Description: ',
        addModify: 'Add/modify informations',
        zipcode: 'Postal code: ',
        maxTravelTime: 'Maximum travel time: ',
        lang: 'Language preference: ',
        modifyModal: {
          title: "Profile's modification",
        },
      },
      reports: {
        title: 'My reports',
        inspection_details: {
          disclaimer:
            'CarInspect is a visual inspection. You agree that if you purchase any vehicle inspected by CarInspect, you are fully responsible for all costs for repairs and/or maintenance on your vehicle. The list of the Expected costs may not be exhaustive, and costs may vary.',
          all_rights: 'CarInspect © All rights reserved.',
          acces_denied: 'Acces denied',
          tab_synthesis: {
            waitingForRecommendation: 'Waiting for recommendation',
            noMajorRepairsNeeded: 'No major repairs needed',
            synthesis: 'Summary',
            vehicle_information: 'Vehicle information',
            information_on_vehicle: 'IDENTIFICATION',
            brand: 'Make:',
            model: 'Model:',
            version: 'Trim',
            odo_before: 'Odometer before inspection:',
            odo_after: 'Odometer after inspection:',
            addPhoto: 'Add photo',
            add: 'Add',
            delete: 'Delete',
            photoDescription: 'Photo description',
            uploadPhoto: 'Upload photo',
            save: 'Save',
            undo: 'Undo',
            addFaultyComponent: 'Add Faulty Component for ',
            modifyFaultyComponent: 'Modify Faulty Component for ',
            addPartNumber: 'Add Part Number',
            confirmRemoval: 'Confirm Removal',
            errorMsg: 'Are you sure you want to remove the faulty component:',
            confirmMsg: 'This action cannot be undone.',
            year: 'Year:',
            mileage: 'Odometer',
            requires_attention_immediately:
              'Requires your attention immediately',
            requires_attention_soon: 'Requires your attention soon',
            grade: {
              perfect_condition:
                'Vehicle in excellent condition that does not require any major repairs.',
              average_condition:
                'Vehicle in good condition that requires minor/major repairs. ',
              poor_condition:
                'Vehicle in bad condition that requires major repairs.',
              drive_worthy: 'Drive-worthy',
              unsafe: 'Unsafe',
              showroom: 'Showroom',
            },
            is_in: 'is in',
            bad: 'bad',
            average: 'average',
            on: 'on',
            elements_attention: 'Elements that need your attention',
            elements_attention_disclaimer:
              'The cost estimates provided are for informational purposes only and are subject to variation due to several factors, including parts (new, used, remanufactured), labor rate, location, etc.',
            totalCost: 'Total Estimated Cost',
            totalCost_attention_disclaimer:
              "*Please note that the prices provided are estimates and may vary depending on the vehicle's model, brand, year, and part availability.",
            priceNotAvailable: 'Price not currently available',
            date_of_inspection: 'Date of inspection',
            time_of_inspection: 'Time of inspection',
            city: 'City',
            recommended: 'Recommended for purchase',
            general_comment_inspector: 'Comments',
            inspector_kiwiz: 'Inspector:',
            first_paragraph:
              'I am a mechanical designer, I work in machine safety for the industry and I studied mechanical engineering.',
            second_paragraph:
              'I am passionate about everything that runs since my childhood: cars, trucks, motorcycles ... I spend much of my free time to expand my knowledge of cars and mechanical sports!',
            third_paragraph:
              "If I started the CarInspect adventure, it's to share my expertise in mechanical engineering and to accompany my customers in the right choice of their future car.",
            cost_of_repairing: 'Expected costs',
            no_repair_required: 'No additional cost to be expected',
            required_to_repair: 'Elements',
            potential_to_repair: 'Potential for repair',
            problem: 'Observations',
            cost: 'Costs',
            vin: 'VIN:',
            color: "Vehicle's color",
            interiorColor: 'Interior color',
            // Possible colors
            Blanc: 'White',
            Noir: 'Black',
            Gris: 'Gray',
            Argent: 'Silver',
            Bleu: 'Blue',
            Rouge: 'Red',
            Brun: 'Brown',
            Green: 'Vert',
            Jaune: 'Yellow',
            White: 'White',
            Black: 'Black',
            Gray: 'Gray',
            Silver: 'Silver',
            Blue: 'Blue',
            Red: 'Red',
            Brown: 'Brown',
            Vert: 'Vert',
            Yellow: 'Yellow',
            interiorFinish: 'Interior finish',
            // Finis possibles
            Leather: 'Leather',
            'Half leather': 'Half leather',
            Fabric: 'Fabric',
            Vinyl: 'Vinyl',
            Cuir: 'Leather',
            'Mi-cuir': 'Half leather',
            Tissu: 'Fabric',
            Vinyle: 'Vinyl',
            fuel: 'Fuel type',
            motor: 'Engine',
            firstHand: 'First hand vehicle',
            finalOffer: 'Final negotiated offer',
            recommended_purchase: 'Recommended purchase: ',
            transmission: 'Transmission ',
            motricity: 'Motricity ',
            visibleEngineLeak: 'Absence of engine leaks',
            visibleTransmissionLeak: 'Absence of transmission leaks',
            visibleDifferentialLeak: 'Absence of differential leaks',
            visibleShockAbsorbersLeak: 'Absence of shock absorbers leaks',
            brakeSystemLeak: 'Absence of brake system leak',
            radiatorHose: 'Radiator hoses',
            radiator: 'Cooling radiator',
            chargingSystem: 'Charging system',
            abnormalSmoke: 'Absence of abnormal smoke',
            handBrake: 'Hand brake',
            cruiseControl: 'Cruise control',
            sunVisor: 'Sun visor',
            steeringWheelAdjustment: 'Steering wheel adjustment',
            brakeService: 'Brake service',
            motorManagement: 'Motor management',
            regenBraking: 'Regenerative braking',
            upsale: {
              title: 'Market Analysis',
              overall: ['Among', 'currently on sale, we observed that :'],
              avgIndividuals: ' Average selling price - private sellers :',
              avgMarkte: 'Average selling price - dealerships :',
              mileage: 'Average mileage :',
              avgSellTime: ['Average sales time:', 'days'],
              taxe: 'taxe included',
            },
            opinion: {
              title: "CarInspect's opinion",
              intro:
                "After a careful inspection, here's my conclusion on the inspected vehicle:",
              aesthetic: {
                Excellent:
                  'The body, the interior and the cleanliness of the vehicle are in perfect condition and I have nothing to report.',
                Bon: 'The vehicle has few cosmetic defects.',
                Good: 'The vehicle has few cosmetic defects.',
                Moyen:
                  "The vehicle has several aesthetic defects (I invite you to consult the report's second tab)",
                Average:
                  "The vehicle has several aesthetic defects (I invite you to consult the report's second tab)",
                Mauvais:
                  'The aesthetic condition of the vehicle has been neglected over time and would require significant investment to fix.',
                Bad: 'The aesthetic condition of the vehicle has been neglected over time and would require significant investment to fix.',
              },
              mechanical: {
                Excellent:
                  'The vehicle has no mechanical defects and drives like a new one.',
                Bon: 'The vehicle has a good mechanical condition and few repairs need to be done.',
                Good: 'The vehicle has a good mechanical condition and few repairs need to be done.',
                Moyen:
                  "The vehicle's mechanical condition is not perfect and a few repairs need to be done.",
                Average:
                  "The vehicle's mechanical condition is not perfect and a few repairs need to be done.",
                Mauvais:
                  'The mechanics of the vehicle are in poor condition and/or have been badly handled. Many costs are expected.',
                Bad: 'The mechanics of the vehicle are in poor condition and/or have been badly handled. Many costs are expected.',
              },
              functional: {
                Excellent:
                  'All equipments works perfectly and no repairs are expected.',
                Bon: 'The equipment is mostly functional and few interventions will be necessary.',
                Good: 'The equipment is mostly functional and few interventions will be necessary.',
                Moyen:
                  'Several interventions will be necessary to be able to take full advantage of the equipment and/or the various functionalities of the vehicle.',
                Average:
                  'Several interventions will be necessary to be able to take full advantage of the equipment and/or the various functionalities of the vehicle.',
                Mauvais:
                  'Many equipments needs attention to work properly. The costs to repair everything could be high.',
                Bad: 'Many equipments needs attention to work properly. The costs to repair everything could be high.',
              },
              conclusion:
                'For more details, I invite you to read the entire report where you can find my explanatory notes and photos.',
            },
            recommendation: {
              title: 'Recommendations',
              maintenance: 'Car maintenance',
              replacement: 'Replacement',
              repair: 'Repair',
              aestheticTreatment: 'Aesthetic treatment',
              levelAdjustment: 'Level adjustment',
              specializedDiagnosis:
                'Diagnostic required to determine the source of the problem.',
              instantDiagnosis: 'Instant diagnostic',
              wiperReplacement: 'Windshield wipers replacement',
              replacementVariableCost: 'Replacement required, variable cost',
              batteryPoleMaintenance: 'Battery pole maintenance',
              batteryReplacement: 'Battery replacement',
              exhaustPlateFix: 'Exhaust plate fix',
              exhaustSystemRepair: 'Exhaust system repair',
              powerSteeringFluidReplacement:
                'A system leak is present or has been present and the level has been incorrectly set. Requires intervention and fluid draining.',
              powerSteeringFluidDrainage:
                'Drainage of power steering system recommended',
              coolantSelfAdjusting:
                'The system adjusts itself, no intervention required',
              accessoryBeltReplacement: 'Accessory belt replacement',
              wiperFluidFilling: 'Filling required',
              brakeFluidReplacement:
                'Replacement of brake system hydraulic fluid',
              brakeFluidIntervention:
                'Intervention required. Unsafe vehicle. Suspected system leak and/or human error in handling.',
              brakeFluidOk:
                'No intervention required on the brake fluid. The level is related to the general condition of the 4 wheel brakes.',
              oilFilterChange: 'Oil and filter change',
              toFix: 'To be fixed',
              anomalyRange:
                'Diagnostic required to determine the source of the problem. The fees listed represent only the average cost of a diagnostic. Repair costs can range from ${{ minCost }} to ${{ maxCost }}.',
              airFilterReplacement: 'Engine air filter replacement',
              interiorShampoo: 'Car interior shampoo',
              chargerReplacement: 'Charger replacement',
              cargoCoverReplacement: 'Cargo cover replacement',
              brakeMaintenance: 'Brake maintenance',
              brakeReplacement:
                '{{ count }} {{ frontBack }} brakes replacement required',
              brakeService: 'Brake service',
              motorManagement:
                'The problem cannot be simply identified and requires a complete diagnostic in a specialized workshop. The fees indicated are representative of an average diagnostic cost for a hybrid system.',
              regenBraking:
                'The system is non-functional or partially functional and the problem cannot be simply identified and requires a complete diagnostic in a specialized workshop. The costs indicated are representative of an average diagnostic cost.',
              leakDiagnostic:
                'Diagnostic required before performing an intervention on the leak. The fees listed represent only the average cost of a diagnostic but not the entire reparation cost.',
              leakShampooAndDiagnostic:
                "The zone needs to be cleaned to find the leak's origin. Then, a diagnostic is required before performing an intervention. The fees listed represent only the average cost of a cleaning and diagnostic but not the entire reparation cost.",
              shockAbsorbersLeak:
                '{{ frontBack }} shock absorbers need to be replaced. The indicated costs are representative of the average cost of a shock absorber replacement, the amount may vary.',
            },
            connectorType: {
              SAEComboCCS: 'SAE Combo CCS',
            },
          },
          tab_details: {
            downloadAsPdf: 'Download as a PDF',
            downloadStarted: 'Downloading !',
            goodStart: 'Good engine start',
            not_verified_comment: 'Comment: (not verified element(s)):',
            not_feasible_comment: 'Road test could not be completed because : ',
            exterior: 'Exterior',
            road_test: 'ROAD TEST',
            details: 'DETAILS',
            elements: 'Elements',
            tires: 'Tires',
            comment: 'Comments',
            not_verified: 'Not verified',
            linear_acceleration: 'Constant acceleration',
            motorIdle: 'Idle motor',
            temperature: 'Temperature and pressure build-up',
            driving_line: 'Road handling',
            function_direction: 'Responsive steering',
            easy_shifting: 'Gear shifting',
            clutch_grip: 'Clutch grip',
            loudTransmission: 'Normal transmission noise',
            loudDifferential: 'Normal differential noise',
            running_gear: 'Suspension component',
            motor_vibration: 'No vibrations or abnormal noises',
            brake_noises: 'Braking',
            esthetic: 'AESTHETIC',
            equipment: 'Equipments',
            keys: 'Number of keys included: ',
            manual: 'Manual included: ',
            floor_mat: 'Floor mat included: ',
            floor_mat_summer: 'Summer mat condition: ',
            floor_mat_winter: 'Winter mat condition: ',
            trunk_equipment: 'Equipment present: ',
            additional_equipment: 'Additional equipment(s) included: ',
            interior: 'Interior',
            cleanliness: 'Cleanliness',
            odor: 'Smell',
            Peu: 'Few',
            Beaucoup: 'Many',
            stains: 'stains/tears',
            roof_stains: 'Roof',
            seat_stains_front: 'Front seats',
            seat_stains_back: 'Rear seats',
            trunk_stains: 'Trunk',
            cargoCover: 'Cargo cover',
            trunk_rust: 'of rust in the bottom of the trunk',
            floor: 'Floor',
            bodywork: 'Bodywork',
            bumper: 'Bumpers',
            windshieldwiper: 'Windshield wipers',
            optical_block: 'Exterior lights',
            mirror: 'Mirrors',
            wing: 'Car fenders',
            door: 'Doors',
            lower_body: 'Car sills',
            rim: 'Rims',
            hood: 'Hood',
            trunk: 'Tailgate',
            roof: 'Roof',
            roof_opening: 'Roof opening',
            functional: 'FUNCTIONAL',
            safety_belt: 'Safety belts',
            front_seat_adjustment: 'Front seat adjustments',
            audio_system: 'Audio system',
            dashboard: 'Dashboard',
            front_windshield_wiper: 'Front windshield wiper',
            rear_windshield_wiper: 'Rear windshield wiper',
            air_conditioning: 'Air conditioning',
            electric_windows: 'Electric windows',
            driver_mirror: 'Driver mirror',
            interior_mirror: 'Interior mirror',
            passenger_mirror: 'Passenger mirror',
            glove_box: 'Glove box',
            trunk_opening: 'Trunk opening',
            additional_options: 'Additional options: ',
            lights: 'Lights',
            front: 'Front',
            rear: 'Rear',
            front_passing_lamps: 'Position lights',
            front_main_beam: 'Low beam light',
            front_head_lights: 'High beam light',
            fog_lights: 'Fog lights',
            turn_signal: 'Turn signals',
            interior_light: 'Interior lights',
            position_lights: 'Position lights',
            brake_lights: 'Brake lights',
            reversing_lights: 'Reverse lights',
            license_plate_lights: 'License plate lights',
            windshield: 'Front windshield',
            backwindshield: 'Rear windshield',
            front_driver: 'Front driver: ',
            rear_driver: 'Rear driver: ',
            front_passenger: 'Front passenger: ',
            rear_passenger: 'Rear passenger: ',
            door_opening: 'Door openings',
            brake: 'Brake System',
            tire_wear: 'Tread',
            engine: 'MECHANICAL',
            obd_scanner: 'Check engine lights',
            accesory_belt: 'Accessory belt',
            battery: '12V Battery condition',
            air_filters: 'Air filter condition',
            windshield_washer_fluid: 'Windshield washer fluid level',
            brake_fluid: 'Brake fluid level',
            powerSteering_fluid: 'Power steering fluid level',
            coolant_fluid: 'Coolant level',
            engine_oil_level: 'Engine oil level',
            front_driver_side: 'Front driver side',
            front_passenger_side: 'Front passenger side',
            rear_driver_side: 'Rear driver side',
            interior_rear_driver_side: 'Rear interior driver side',
            rear_passenger_side: 'Rear passenger side',
            interior_rear_passenger_side: 'Rear interior passenger side',
            used: 'Worn out, still good for 1-2 seasons',
            change: 'To be changed',
            new: 'New',
            none: 'None',
            windshieldwiper_functional: 'Windshield wipers',
            mirror_functional: 'Mirrors',
            wheels: 'Wheels',
            remote_opening: 'Remote opening: ',
            plastics_trim: 'Plastics and trim',
            horn: 'Horn',
            door_locking: 'Door locking',
            fuel_door: 'Fuel door',
            charging_cable: 'Charging cable',
            usbPort: 'USB port(s)',
            Plug12v: '12V plug(s)',
            visible_leak: 'Absence of leaks',
            visibleEngineLeak: 'Absence of engine leaks',
            visibleTransmissionLeak: 'Absence of transmission leaks',
            visibleDifferentialLeak: 'Absence of differential leaks',
            visibleShockAbsorbersLeak: 'Absence of shock absorbers leaks',
            brakeSystemLeak: 'Absence of brake system leaks',
            radiatorHose: 'Radiator hoses',
            radiator: 'Cooling radiator',
            chargingSystem: 'Charging system',
            abnormalSmoke: 'Absence of abnormal smoke',
            handBrake: 'Hand brake',
            cruiseControl: 'Cruise control',
            sunVisor: 'Sun visor',
            steeringWheelAdjustment: 'Steering wheel adjustment',
            paint: "Car body's painting",
            carpets: 'Vehicle carpeting',
            instrument_cluster: 'Instrument cluster',
            maintenance_invoices: 'Maintenance invoice included :',
            doors_plastics: 'Doors plastics and trim',
            dashboard_plastics: 'Dashboard',
            central_console_plastics: 'Center console',
            four_wheel_drive: 'Four-wheel drive',
            exhaust_system: 'Exhaust system',
            rust_inhibitor: 'Rustproofing treatment: ',
            vehicle_tires: "Vehicle's tires :",
            tires_2set: '2nd tire set :',
            pre_mounted_tires: 'Premounted tires (2nd set) : ',
            tire1: '1st tire :',
            tire2: '2nd tire :',
            tire3: '3rd tire :',
            tire4: '4th tire :',
            tire5: '5th tire :',
            tire6: '6th tire :',
            home_charger_working: '120V Charger working',
            home_charger: '120V Charger aesthetic',
            electrical_connector: 'Electrical connector',
            charge_door: 'Charge door',
            'Éclat(s)': 'Stone chip',
            electricSystem: 'Electric system',
            motorManagement: 'Motor management',
            regenBraking: 'Regenerative braking',
            maxBatteryCapacity: 'Maximum battery capacity',
            heatingSystem: 'Heating System',
          },
          tab_photos: {
            general: 'General',
            defects: 'Defects',
          },
        },
        iAInspectionDetails: {
          title: 'Digital Inspection Report',
          allRightsReserved: 'CarInspect © All rights reserved.',
          synthesis: {
            addFaultyComponent: 'Add faulty component',
            modifyFaultyComponent: 'Modify',
            removeFaultyComponent: 'Remove',
            vehicleDetails: 'Vehicle details',
            inspectionDetails: 'Inspection details',
            inspectionId: 'ID:',
            inspectionType: 'Type:',
            inspectionLocation: 'Location:',
            carInspector: 'CarInspector:',
            completionDate: 'Completion date (YYYY/MM/DD):',
            report: 'Report',
            carProblem: 'Components to inspect :',
            isVehicleModified: 'Modified vehicle',
            commercialUsage: 'Proof of commercial use',
            accidentProof: 'Damaged vehicle',
            isRegistrationCardAvailable: 'Registration card available',
            carProblemComment: 'Comment for',
            additionalRequests: 'Additional requests :',
            odometer: 'Odometer',
            issueToBeConfirmed: 'Issue to be confirmed: ',
            confirmedIssue: 'Confirmed issue?',
            faultyComponents: 'Faulty component',
            component: 'Component',
            requiredWork: 'Required work: ',
            partNumbers: 'Part numbers: ',
            laborTime: 'Labor time: ',
            partCost: 'Part Cost: ',
            source: 'Source: ',
            price: 'Price: ',
          },
          disclaimer: {
            title: 'Disclaimer',
            textOne:
              'The inspection conducted by CarInspect is purely visual, and we are not liable for any hidden defects that were not visible during the assessment. No disassembly of parts or components is carried out during this process. You understand that while CarInspect inspectors follow industry-standard techniques to detect issues, not all potential problems may be identified. The condition and age of the vehicle at the time of inspection should be taken into account, and the findings should be reviewed accordingly. Road tests are performed within the legal speed limits of the inspectionlocation.',
            textTwo:
              "The report issued by CarInspect reflects the vehicle’s condition at the time of inspection only. Buyers are responsible for conducting their own visual check of the vehicle at the time of purchase, as CarInspect's report is limited to the inspection date. This report cannot replace an official safety certificate, and CarInspect does not verify whether the vehicle is subject to any manufacturer recall notices. It is up to the buyer to check for such recalls.",
            textThree:
              'CarInspect holds no responsibility for the terms or outcomes of any transaction between parties related to a vehicle inspected by our service, nor for settling any disputes that may arise from such transactions.',
            textFour:
              'To the maximum extent allowed by law, CarInspect will not be held liable for any indirect or consequential losses, including but not limited to loss of business, profits, data, contracts, or expected savings. Additionally, CarInspect is not responsible for any costs associated with  delays, financing, or operational increases.',
          },
          photosTab: {
            principal: 'Principal photos',
            additional: 'Additional photos',
            photo1: 'First photo',
            photo2: 'Second photo',
            photo3: 'Third photo',
            photo4: 'Fourth photo',
            photo5: 'Fifth photo',
            photo6: 'Sixth photo',
            photo7: 'Seventh photo',
            photo8: 'Eighth photo',
            photo9: 'Ninth photo',
            photo10: 'Tenth photo',
            photo11: 'Eleventh photo',
            photo12: 'Twelfth photo',
            photo13: 'Thirteenth photo',
            photo14: 'Fourteenth photo',
            photo15: 'Fifteenth photo',
            doorNiv: 'VIN on door',
            windshieldNiv: 'VIN on windshield',
            licensePlate: 'License plate',
            registrationCard: 'Registration card',
            vehicleModification: 'Vehicle modification',
            vehicleModification2: 'Second vehicle modification',
            vehicleModification3: 'Third vehicle modification',
            vehicleModification4: 'Fourth vehicle modification',
            vehicleModification5: 'Fifth vehicle modification',
            commercialUsage1: 'Commercial usage',
            commercialUsage2: 'Second commercial usage',
            commercialUsage3: 'Third commercial usage',
            accidentProof1: 'Accident proof',
            accidentProof2: 'Second accident proof',
            accidentProof3: 'Third accident proof',
            accidentProof4: 'Fourth accident proof',
            accidentProof5: 'Fifth accident proof',
          },
        },
      },

      businessInspection: {
        login: 'Login',
        password: 'Enter your password',
        businessName: 'Name',
        businessStreet: 'Address',
        businessCity: 'City',
        businessZipCode: 'Postal code',
        aboutBusiness: 'Information about the entity that owns the vehicle',
        comment: 'Further information',
        commentSection:
          'Please share any information that could be relevant during the inspection',
        delai: 'Inspection delay',
        validation: 'Validation',
        delai24: '24h',
        delai48: '48h',
        conditions:
          'I consent to be billed as per the commercial terms defined in the contract.',
        accept: 'Accept',
        inspectionType: 'Inspection type',
        niv: 'VIN Vehicle Identification Number',
        TroubleShoot: 'Troubleshoot',
        PSI: 'PSI',
        Complete: 'Complete',
        'Logistic center': 'Logistic center',
        Dealership: 'Dealership',
        Consumer: "Consumer's house",
        Vehicule: 'Vehicle or component',
        Huile: 'Oil test',
        PreAchat: 'Pre-purchase inspection',
        ComposantSpecifique: 'Inspection of a specific component',
        book: 'Book Inspection',
        confirmationModal: {
          title: 'Reservation confirmed',
          text: 'Your reservation has been confirmed. Thanks for working with us.',
        },
        phoneNumber: {
          title: 'Phone Number',
        },
      },
      psiForm: {
        engineComponent: 'Engine',
        transmissionComponent: 'Transmission',
        driveTrainComponent: 'Powertrain / Drivetrain',
        oilLeaksComponent: 'Oil leaks',
        structureComponent: 'Structure/Frame',
        suspensionComponent: 'Suspension/Subframe',
        catalyticConverterComponent:
          'Catalytic converter(s) / Exhaust manifolds',
        normalWearComponent: 'Normal Wear & Tear',
        warningLightsComponent: 'Warning Lights On',
        equippedTPMSComponent: 'Equipped with TPMS',
        srsComponent: 'SRS (airbags)',
        acComponent: 'A/C System (not equipped/problem)',
        electricalComponent: 'Electrical accessories',
        photoComponent: 'Inspection photos (paintwork, defaults, etc.)',
        absComponent: 'ABS',
        testdriveComponent: 'Test drive',
      },
      psiFormEblock: {
        equippedTPMSComponent: 'Equipped with TPMS',
        engineComponent: 'Engine Operation Normal',
        transmissionComponent: 'Transmission Operation Normal',
        driveTrainComponent: 'Powertrain / Differentials',
        suspensionComponent: 'Suspension And/OR Subframe Damaged',
        electricalComponent:
          'Computer / Electrical Operation Normal (INCL DVD/ NAV/Touch Screen)',
        catalyticConverterComponent:
          'Cat converters / Exhaust Manifolds Operation Normal',
        oilLeaksComponent: 'Oil Leaks : Engine / Transmission / Differencials',
        odometerComponent: 'Odometer Operation Normal / Gauges Working',
        absComponent: 'ABS Operation Normal',
        srsComponent: 'Airbags Operation Normal',
        warningLightsComponent:
          'Dash Lights: Engine, Airbag,TPMS, ABS, AWD, Trac Control, ETC.',
        codeComponent:
          'Scan for codes if any Warning Lights on (Note Warning Lights)',
        acComponent: 'A/C System Blows Cold (Note if A/C is Not Working)',
        structureComponent: 'Structural / Frame (Visual Inspection Only)',
      },

      purchase: {
        // firstStepTitle: 'Verify availability',
        vehicleInformation: 'Vehicle information',
        make: 'Make',
        model: 'Model',
        year: 'Year',
        outsideZoneCheckbox:
          'I confirm that I have contacted the CarInspect team to verify the possibility of performing an inspection in this zone.',
        carfaxUrl: 'Carfax',
        processRequest: 'Did you understand the whole process ?',
        mileage: 'Mileage',
        VIN: 'VIN',
        formFilled: 'The form is already filled, thank you!',
        invalidPurchaseID:
          'This is an invalid purchase ID. Please contact us at info@carInspect.com',
        sellerTypes: {
          title: 'Seller type',
          dealer: 'Dealership',
          private: 'Private seller',
          owner: 'I am the owner',
        },
        verifyAvailability: 'In which city is the vehicle located?',
        address: "Seller's address",
        sellerCoordinates: 'Seller details',
        phoneNumber: 'Phone number',
        sellerPhoneNumber: "Seller's phone number",
        sellerName: "Seller's name",
        city: "Seller's city",
        dealerName: 'Dealer Name',
        adUrl: 'Ad URL',
        zipCode: 'Seller Postal Code',
        zipeCodeUser: 'Postal Code',
        price: 'Displayed price',
        buyerInfo: 'Personal details',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email address',
        confirmEmail: 'Confirm email',
        callAfterInspection:
          'The inspector will call you for an assessment of the condition of the car and answer your questions (Free)',
        callAfterInspectionTitle: 'Post-inspection phone call',
        notes: 'Additional notes',
        terms: 'By pressing the button below, ',
        invalidEmail: 'The email addresses do not match',
        upsaleMarketValue: 'Get a market analysis to help you make a good deal',
        upsalePhoto: 'Get a detailed photo report of aethetic defects.',
        bookInspection: 'Book my inspection',
        payment: 'Payment',
        upsaleForm: 'My CarInspect inspection',
        reviewCart: 'Proceed to checkout',
        totalPrice: 'For a total price of ',
        selected: 'You have selected a ',
        for: 'for a',
        options: 'With the following options:',
        bookingConfirmation:
          'Congratulations, your inspection request has been validated. Proceed to payment to confirm it.',
        pay: 'Pay',
        confirmPayment:
          'I consent to get contacted to obtain the best price for auto insurance; this service is obligation-free.',
        summary: 'Summary of ',
        back: 'Back',
        next: 'Next',
        backToWebsite: 'Back to website',
        invalidZipCodeFormat: 'Invalid Zip Code, please double check address.',
        availableZone:
          'We are available to inspect your vehicle within the next 24 hours, or, if not possible within the next 48 hours. After the booking, CarInspect arranges the time with seller. We take care of everything.',
        unavailableZone:
          'Please contact the CarInspect team to check availability in this area.',
        questions:
          'Do you have any further questions? Please feel free to |contact us| and we will be happy to assist you.  ',
        exoticCar: 'Please |contact us',
        submit: 'Submit',
        leadOrigin: {
          title:
            'Where did you hear about CarInspect? (special thanks from the marketing team 🤗)',
          placeholder: 'Choose an option',
          search: 'Internet search',
          marketing: 'Facebook or Instagram ads',
          kijiji: 'Kijiji Autos',
          media: 'In the press, radio, etc.',
          blog: 'Blog post',
          mouth: 'Word of mouth',
          other: {
            title: 'Other',
            placeholder: 'Specify',
          },
          otherRequest: 'Please specify',
          preferNotToAnswer: 'I prefer not to answer',
          socialNetworks: 'Social Networks',
        },
        upsale: {
          marketValue: 'Market Analysis',
          image: 'Detailed photo report',
        },
        carfax: {
          title: 'Carfax report URL',
          subText:
            'Optional. Our inspector can verify the vehicle history using the CARFAX report. You can |purchase the CARFAX by clicking HERE|.',
        },
        inspectionCreditCode: {
          title: 'Inspection credit code',
          redeem: 'Use the credit',
          error: {
            title: 'This code ',
            'Not found': 'does not exist',
            'Already used': 'has already been used',
          },
          confirmation: {
            title: 'Your inspection is now confirmed',
            message: 'You will receive an email confirmation soon.',
          },
        },
        clientOwner: "It's the client's car.",
      },
      inspectionPage: {
        B2BTitle: 'Business Inspection',
        completeTitle: 'Complete inspection',
        iATitle: 'Insurance inspection',
        exactInfo: 'Is the information correct?',
        validate: 'Validate',
        backToForm: 'Back to form',
        reportProblem: 'Report a problem',
        problemPlaceholder: 'Specify the problem',
        requestCorrection: 'Send the problem',
        correctionDelayWarning:
          'The report has been sent to be corrected. A 2 hour delay might be required before it is sent to the client.',
        completedInspectionModal: {
          title: 'Inspection is complete',
          message: 'Close and submit this inspection?',
          buttonText: 'Yes, I confirm',
        },
      },
    },
    sequence: {
      rating: {
        question: 'Is this model interesting to you?',
      },
    },
    homepage: {
      logo_subtitle: 'Your trusted partner to buy your used car',
      tiles: {
        title: 'Welcome',
        search: {
          title: 'Search',
          description: 'Select your favorite models',
        },
        profile: {
          personal_information: 'Personal information',
          research_criteria: 'Criterias',
          preferences: 'Preferences',
          save: 'Save',
          my_profile: 'My profile',

          first_tab: {
            first_name: 'First name',
            last_name: 'Last name',
            email: 'Email',
          },

          second_tab: {
            search_radius: 'Search radius',
            type_of_occasion: 'Type of occasion',
            go_study: 'Go study',
            go_work: 'Go work',
            travel: 'Travel',
            professional_use: 'Professional use(Eva,Uber,Etc.)',
            drifting: 'Drifting in the Maxi parking lot',
            other: 'Other',
            fuel: 'Fuel',
            electric: 'Electric',
            Hybrid: 'Hybrid(electric and gasoline)',
            gasoline: 'Gasoline',
            no_preference: 'No preference',
            number_of_passenger: 'Number of passengers',
          },
        },
        ads: {
          title: 'My ads',
          description: 'View your ads',
        },
        criterias: {
          title: 'Mes critères',
          description: 'Modifie à tout moment tes critères de recherche',
        },
        insurance: {
          title: 'Insurance',
          description: 'Compare the insurance and find the cheapest',
        },
        ressources: {
          title: 'Ressources',
          description: 'Guides to help you every step of the way',
        },
        inspection: {
          title: 'Inspection',
          description: 'Make sure your future car is in good condition',
        },
      },
    },
  },
  sequence: {
    sequence: 'In progress',
    home: 'Dashboard',
    help: 'I need help',
    completed: 'completed',
    vehicle_suggestion:
      'Tu dois avoir donné une note à chacun des véhicules avant de continuer 😉',
  },
  admin: {
    menu: {
      general: 'General',
      ads: 'Ads',
      guides: 'Guides',
      users: 'Users',
      sequences: 'Sequences',
      settings: 'Settings',
      vehicles: 'Vehicles',
      todo: "'TODO' list",
      ressources: 'Ressources',
    },
    general: {
      'waiting-list': {
        title: {
          waiting: 'Awaiting suggestions',
          'waiting/ads': 'Awaiting ads',
          'waiting/purchase': 'Awaiting purchase',
          inprogress: 'In progress',
        },
      },
      'total-users': 'Registered clients',
    },
    sequence: {
      title: 'Sequences management',
      delete: 'Are you sure you want to delete this sequence?',
      new: 'New sequence',
      show_done_button: "Show 'Next' button",
      notify_admins: 'Notify admins when starting this step',
      waiting_on_expert_label: 'Text while waiting for a CarInspect expert',
      step: {
        'vehicle-suggestions': 'Vehicles Suggestions',
        text: 'Text',
        payment: 'Paiement',
      },
      hasEndModal: 'Show end modal',
      hasInitialModal: 'Show initial modal',
    },
    vehicles: {
      title: 'Vehicles',
      makes: 'Makes',
      types: 'Types',
      years: 'Years',
      modal: {
        title: 'Vehicle Modification',
        infos: 'INFOS',
        form: 'FORM',
        make: 'Make',
        model: 'Model',
        type: 'Type',
        drivetrain: 'Drivetrain',
        fuel: 'Fuel',
        consumption: 'Fuel consumption',
        maintenance: 'Maintenance',
        origin: 'Origin',
        warranty: 'Warranty',
        resell_value: 'Resell value',
        category: 'Category',
        advantages: 'Advantages',
        disadvantages: 'Disadvantages',
        image: 'Image',
        thumbnail: 'Thumbnail',
        images: 'Images',
        selection: 'Selection',
        search: 'Search',
        reliability: 'Reliability',
        money_value: 'Value for Money',
        money_value_short: 'Price',
        comfort: 'Comfort',
        fun_factor: 'Fun to Drive',
        fun_factor_short: 'Fun',
        security: 'Security',
        cargo_space: 'Cargo Space',
        recalls: 'Recalls',
        recommended_drivetrain: 'Recommended Drivetrain',
        year_min: 'Year (min)',
        year_max: 'Year (max)',
      },
    },
    users: {
      title: 'Users',
      display_name: 'Name',
      email: 'Email',
      progression: 'Progression',
      creation: 'Creation',
      lastSignInTime: 'Last Login',
      modal: {
        title: 'User information',
        step: 'Step',
        completed_true: 'Completed',
        completed_false: 'In progress',
        suggest_vehicles: 'Suggest vehicles',
        suggestions: {
          title: 'Suggestion de véhicules',
        },
        close_entry: 'Close entry',
        close_entry_question: 'Are you sure you want to close this entry?',
        reset_entry: 'Reset entry',
        reset_entry_question: 'Are you sure you want to reset this entry?',
        skip_step: 'Skip step',
        reset_step: 'Reset step',
        skip_step_question:
          'Are you sure you want to skip this step for this user?',
        reset_step_question:
          'Are you sure you want to reset this step for this user?',
        no_entries: 'No entries were found',
        no_ads: 'No ads were found',
        no_vehicle_selected: 'No vehicle selected',
        has_paid: 'Has paid for the service',
        has_bought: 'Has bought a car',
      },
      search: {
        title: 'Search',
      },
      tabs: {
        sequences: 'Sequences',
        ads: 'Ads',
      },
      ads: {
        modal: {
          title: 'Ad',
          site: 'Site',
          url: 'Url',
          description: 'Description',
          valid: 'The ad is valid',
          invalid: 'The ad is invalid',
          price: 'Price',
          thumbnail: 'Thumbnail',
          year: 'Year',
          version: 'Version',
          mileage: 'Mileage',
          published: 'Published',
          buying_budget: 'Buying Budget',
          taxes: 'Taxes',
          warranty: 'Warranties',
          yearly_budget: 'Yearly Budget',
          licenses: 'Licenses',
          maintenance: 'Maintenance',
          fuel: 'Fuel',
          insurance: 'Insurance',
          tax_dealer: 'Uses dealer taxes',
          tax_person: 'Only one taxe is applied',
          receive_announcement: 'Receive ads',
          research_announcement:
            'The search is based on your favorite models and the criteria added in the My criteria page.',
          ready_to_receive:
            'We need some more information in order to start the search. Please complete the following fields.',
        },
      },
    },
    ads: {
      title: 'Ads',
      creation: 'Création',
    },
    settings: {
      title: 'Settings',
      default_sequence: 'Default Sequence',
      vehicle_update_button: 'LeParking - Vehicles update',
      vehicle_update_message:
        'Are you sure you want to update the vehicles? This action is VERY costly. (time + $)',
      vehicle_update_confirmation: 'Are you sure?',
      vehicle_update_confirmation_second: 'One last time. Are you sure?',
    },
    guides: {
      title: 'Guides',
      modal: {
        title: 'Guide Modification',
        name: 'Name',
        description: 'Description',
        enabled: 'Show it to users',
        link: 'Link to the website',
      },
    },
  },
  dashboard: {
    favorites: 'My selection',
    vehicles: 'Find a vehicle',
    create_own_favorite:
      "It's up to you to create your list of favorite models!",
    search_specific_model:
      'Search for specific vehicles, remove or add models!',
    search_best:
      'Once your list of up to 3 models has been chosen, click on "Search for the best ads",',
    find_the_best_market:
      'so that CarInspect can find the best deals on the market!',
    search_best_button: 'Search for the best ads',
    no_favorite:
      "You don't have any favorites, choose some and start your search!",
    vehicle_suggestion:
      "You don't know which vehicles are right for you? Find out using our",
    recommendation_algorithm: 'recommendation algorithm',
    next_search: 'Your next search can be done in : ',
    hour: 'hour',
    searchPlaceholder: 'Find a vehicle',
    completedSelection: {
      title: 'Your list is full!',
      content: 'You can now start your search.',
      tooManyCars:
        'You can only add 3 models to your selection. Make some changes or start your search.',
    },
    email: {
      title: 'Enter your email',
      invalid: 'Not a valid email',
    },
    zipCode: {
      title: 'Enter your zip code',
      invalid: 'Not a valid zip code',
    },
    gearbox: {
      title: 'Gearbox',
      none: 'No preference',
      automatic: 'Automatic',
      manual: 'Manual',
    },
    fuelType: {
      title: 'Fuel type',
      none: 'No preference',
      essence: 'Gas',
      gas: 'Gas',
      diesel: 'Diesel',
      hybride: 'Hybrid',
      hybrid: 'Hybrid',
      'hybride branchable': 'Plug-in hybrid',
      'plug-in hybrid': 'Plug-in hybrid',
      electrique: 'Electric',
      electric: 'Electric',
      ethanol: 'Ethanol',
    },
    distance: {
      title: 'Search radius',
    },
    minYear: {
      title: 'Minimum year',
    },
    maxMileage: {
      title: 'Maximum mileage',
    },
    conditions:
      'I agree to the |Terms and Conditions| and |Privacy Policies| of CarInspect.',
    launchSearch: 'Launch Search',
    validation: {
      title: 'Congratulations!',
      body: 'You will receive the best ads shortly by email.\n\nPsst: The email may arrive in promotions or SPAMS folder.',
    },
  },
  stripe: {
    method: 'Payment methods',
    thank_you: 'Thank you',
    validate_inspection:
      'As soon as an inspector from our network validates the inspection, we will get back to you with the date and time of it.',
    amount_to_pay: 'Amont to pay: ',
    left_to_pay: 'Left to pay: ',
    the: 'the',
    summary_payment: 'Summary of purchase:',
    payment_made: 'Payment made!',

    success: {
      title: 'We confirm your inspection reservation. Thank you!',
      subtitle: 'You will receive a confirmation email soon.',
      stepsTitle: 'Here are the next steps.',
      stepsSubtitle: 'You will be notified by email for each one of them.',
      stepAfterReservation:
        "To finalize your inspection booking, please complete the vehicle information. You can also do it later (You'll receive the link by email)",
      step1: 'One of our inspectors accepts your inspection.',
      step2:
        'The inspector reaches the seller to arrange a date and time for the inspection.',
      step3: 'The inspector completes the inspection.',
      step4:
        'The inspector contacts you by phone if requested and you receive a detailed report by email.',
    },
    failure: {
      title:
        'We could not proceed to payment. Your inspection is still pending.',
    },
    return: 'Back to website',
  },
  inspection: {
    fields: {
      goodStart: 'Good engine start',
      loudTransmission: 'Loud transmission',
      loudDifferential: 'Loud differential',
      constantAccelerationRoadTest:
        'Normal warm-up with constant and linear acceleration',
      normalPressureTempRoadTest:
        'Normal engine temperature and pressure build-up',
      goodLineKeeping:
        'Good handling of the driving line (during acceleration and braking)',
      directionProperFunctioning:
        'Good functioning of the steering component (absence of play and vibrations)',
      smoothGearShift:
        'Easy gear shifting and no snags on the gearbox synchros',
      goodClutch: 'Good clutch grip or no excessive torque converter slip',
      goodSuspension:
        'Absence of anomaly in the suspension (shock absorbers, ball joints, etc.)',
      frontSuspension:
        'Absence of anomaly in the front suspension (shock absorbers, ball joints, etc.)',
      rearSuspension:
        'Absence of anomaly in the back suspension (shock absorbers, ball joints, etc.)',
      engineVibration: 'No abnormal motor vibrations',
      brakeNoice: 'No vibrations or abnormal noises during braking',
      summerFloorMats: 'Summer carpet condition',
      winterFlootMats: 'Winter carpet condition',
      overallCleanliness: 'Overall cleanliness of the interior',
      headliner: 'Stains on the headliner',
      frontSeats: 'Front seat stains',
      backSeats: 'Stains on the rear seat ',
      insideTrunk: 'Stains or debris in the trunk',
      trunkLiner: 'Rust marks on the bottom of the trunk',
      floor: 'Floor condition',
      thicknessPaint: 'Paint thickness',
      frontBumper: 'Front bumper condition',
      backBumper: 'Passenger side rear bumper condition',
      frontWindshieldWiperEsthetic: 'Front windshield wipers arm condition',
      backWindshieldWiperEsthetic: 'Rear windshield wiper arm condition',
      driverFrontOpticalBlock: "Driver's side exterior front lights condition",
      cargoCover: 'Cargo cover',
      driverBackOpticalBlock: "Driver's side exterior rear lights condition",
      passengerFrontOpticalBlock:
        'Passenger side exterior front lights condition',
      passengerBackOpticalBlock:
        'Passenger side exterior rear lights condition',
      driverMirrorEsthetic: "Driver's side front mirror condition",
      passengerMirrorEsthetic: 'Passenger side front mirror condition',
      driverBackWing: "Driver's side rear fender condition",
      driverFrontWing: "Driver's side front fender condition",
      passengerBackWing: 'Passenger side rear fender condition',
      passengerFrontWing: 'Passenger side front fender condition',
      driverFrontDoor: "Driver's side front door condition",
      driverBackDoor: "Driver's side rear door condition",
      passengerFrontDoor: 'Passenger side front door condition',
      passengerBackDoor: 'Passenger side rear door condition',
      driverUnderBody: "Driver's side rocker panel condition",
      passengerUnderBody: 'Passenger side rocker panel condition',
      driverFrontRim: "Driver's side front wheel condition",
      driverBackRim: "Driver's side rear wheel condition",
      passengerFrontRim: 'Passenger side front wheel condition',
      passengerBackRim: 'Passenger side rear wheel condition',
      hood: 'Hood condition',
      trunk: 'Trunk condition',
      roof: 'Roof condition',
      odor: 'Smell',
      safetyBelt: 'Safety belts',
      frontSeatsSettings: 'Front seat adjustments',
      audioSystem: 'Audio system and speakers',
      onboardComputer: 'Onboard computer',
      frontWindshieldWiper: 'Front windshield wipers',
      backWindshieldWiper: 'Rear windshield wipers',
      airConditioning: 'Air conditioner',
      airFilter: 'Air filter',
      electricWindows: 'Electric windows',
      driverMirror: "Driver's side mirror",
      interiorMirror: 'Interior mirror',
      passengerMirror: 'Passenger side mirror',
      gloveCompartment: 'Glove box',
      trunkOpening: 'Opening of the trunk',
      frontWindshield: 'Front windshield condition',
      backWindshield: 'Rear windshield condition',
      driverBackDoorOpening: "Driver's side rear door opening",
      driverFrontDoorOpening: "Driver's side front door opening",
      passengerFrontDoorOpening: 'Front door opening on passenger side',
      passengerBackDoorOpening: 'Opening of the rear passenger door',
      driverBackBrake: 'Rear brake condition',
      driverFrontBrake: 'Front brake condition',
      passengerFrontBrake: 'Front brake condition',
      passengerBackBrake: 'Rear brake condition',
      frontPassingLamps: 'Position lights',
      frontMainBeam: 'Low beam light',
      frontHeadLights: 'High beam light',
      frontFogLights: 'Front fog lights',
      frontFlashingSignalLamps: 'Front turn signals',
      insideLighting: 'Inside lighting',
      backPositionLamps: 'Rear position lights',
      brakeLights: 'Brake lights',
      reversingLamps: 'Reverse lights',
      licensePlateLight: 'License plate lights',
      backFlashingSignalLamps: 'Rear turn signals',
      accessoryBelt: 'Accessory belt condition',
      wiperFluidLevel: 'Windshield washer fluid level',
      brakeFluidLevel: 'Brake fluid level',
      motorOilLevel: 'Engine oil level',
      motorOilQuality: 'Engine oil quality',
      driverShockAbsorber: "Driver's side front shock absorber condition",
      passengerShockAbsorber: 'Passenger side front shock absorber condition',
      driverFrontTirePressure: 'Driver side front tire pressure',
      passengerFrontTirePressure: 'Passenger side front tire pressure',
      driverBackTirePressure: 'Driver side rear tire pressure',
      passengerBackTirePressure: 'Passenger side rear tire pressure',
      driverFrontTireWear: "Driver's side front tire wear",
      driverBackTireWear: "Driver's side rear tire wear",
      interiorDriverBackTireWear: "Driver's side rear interior tire wear",
      passengerFrontTireWear: 'Front passenger tire wear',
      passengerBackTireWear: 'Passenger side rear tire wear',
      isOBDScanner: 'Check engine lights',
      interiorPassengerBackTireWear: 'Passenger side rear interior tire wear',
      driverFrontTire: 'Driver side front tire',
      driverBackTire: 'Driver side rear tire',
      passengerFrontTire: 'Passenger side front tire',
      passengerBackTire: 'Passenger side rear tire',
      carPlastics: 'Plastics and trim',
      doorsPlastics: 'Doors plastics and trim',
      doorPlastics: 'Doors plastics and trim',
      dashboardPlastics: 'Dashboard plastics and trim',
      centralConsolePlastics: 'Center console plastics and trim',
      coolantLevel: 'Coolant level',
      Extra: 'Photo',
      Extra2: 'Photo',
      Extra3: 'Photo',
      Extra4: 'Photo',
      doorLocking: 'Door locking',
      carRemoteOpening: 'Remote opening',
      usbPort: 'USB port(s) ',
      fuelDoor: 'Fuel door',
      chargingCable: 'Charging cable',
      carHorn: 'Horn',
      homeCharger: 'Home charger',
      electricalConnector: 'Electrical connection',
      instrumentCluster: 'Instrument cluster',
      Plug12v: '12V Plug(s)',
      visibleEngineLeak: 'Visible engine leak',
      visibleTransmissionLeak: 'Visible transmission leak',
      visibleDifferentialLeak: 'Visible differential leak',
      visibleShockAbsorbersLeak: 'Visible shock absorbers leak',
      brakeSystemLeak: 'Visible brake system leak',
      paint: "Car body's painting",
      carpets: 'Car floor carpets',
      powerSteeringFluidLevel: 'Power steering fluid level',
      battery: '12V Battery condition',
      exhaustSystem: 'Exhaust system',
      wheelAlignment: 'Wheel alignment',
      Front_tires: 'Front tires',
      Rear_tires: 'Rear tires',
      tires: 'Tires',
      roofOpening: 'Roof opening',
      driverLiftingPoint: 'Lifting point (driver side)',
      passengerLiftingPoint: 'Lifting point (passenger side)',
      passengerBody: 'Passenger underbody',
      cruiseControl: 'Régulateur de vitesse',
      sunVisor: 'Sun visor',
      engineLeak: 'Engine leak',
      motorManagement: 'Motor management',
      regenBraking: 'Regen braking',
      heatingSystem: 'Heating System',
    },

    photos: {
      'Avant du véhicule': 'Front of the vehicle',
      'Arrière du véhicule': 'Rear of the vehicle',
      'Trois-quart avant côté conducteur du véhicule':
        'Three-quarter front driver side of the vehicle',
      'Trois-quart avant côté passager du véhicule':
        'Three-quarter front passenger side of the vehicle',
      'Côté conducteur du véhicule': "Driver's side of the vehicle",
      'Côté passager du véhicule': 'Passenger side of the vehicle',
      'Trois-quart arrière côté conducteur du véhicule':
        'Three-quarter rear driver side of the vehicle',
      'Trois-quart arrière côté passager du véhicule':
        'Three-quarter rear passenger side of the vehicle',
      'Compartiment moteur': 'Engine bay',
      'Compartiment avant': 'Front compartment',
      'Compartiment arrière': 'Rear compartment',
      'Intérieur du coffre': 'Interior of the trunk',
      'Caméra de recul': 'Backup camera',
      'Système de navigation': 'Navigation system',
      carFront: 'Front of the vehicle',
      carBack: 'Rear of the vehicle',
      carThreeQuarterFrontDriver:
        'Three-quarter front driver side of the vehicle',
      carThreeQuarterFrontPassenger:
        'Three-quarter front passenger side of the vehicle',
      carSideDriver: "Driver's side of the vehicle",
      carSidePassenger: 'Passenger side of the vehicle',
      carThreeQuarterBackDriver:
        'Three-quarter rear driver side of the vehicle',
      carThreeQuarterBackPassenger:
        'Three-quarter rear passenger side of the vehicle',
      engineBay: 'Engine bay',
      frontCompartment: 'Front compartment',
      backCompartment: 'Rear compartment',
      trunkCompartment: 'Interior of the trunk',
      rearCamera: 'Backup camera',
      navigationSystem: 'Navigation system',
      driverFrontTire: 'Driver side front tire',
      driverBackTire: 'Driver side rear tire',
      passengerFrontTire: 'Passenger side front tire',
      passengerBackTire: 'Passenger side rear tire',
      roof: 'Roof of the vehicle',
      frontPassengerCompartment: 'Front passenger compartment',
      backPassengerCompartment: 'Back passenger compartment',
      backDriverCompartment: 'Back driver compartment',
      frontDriverCompartment: 'Front driver compartment',
      spareTire: 'Spare tire',
      trunkEquipment: 'Trunk equipment',
      driverFrontLiftingPoint: 'Front lifting point (driver side)',
      cargoCover: 'Cargo cover',
      passengerBackLiftingPoint: 'Back lifting point (passenger side)',
      driverBackLiftingPoint: 'Back lifting point (driver side)',
      passengerFrontLiftingPoint: 'Front lifting point (passenger side)',
      niv: 'VIN photo',
      instrumentCluster: 'Instrument cluster',
      Extra: 'Additional picture',
      Extra2: 'Additional picture',
      Extra3: 'Additional picture',
      Extra4: 'Additional picture',
      tire1: '1st tire from 2nd set',
      saaqSticker: 'SAAQ sticker',
    },

    comments: {
      Yes: 'Yes',
      Oui: 'Yes',
      No: 'No',
      Non: 'No',
      'Yes, with recommended repairs': 'Yes, with recommended repairs',
      'Oui avec réparations recommandées': 'Yes, with recommended repairs',
      Mesure_hors_de_la_fourchette: 'Measurement outside the range',
      suspicion_d_un_accident: 'suspicion of an accident',
      Compris_dans_la_fourchette: 'Within range',
      aucune_preuve_d_accident: 'no evidence of accident',
      'Déchirure(s)': 'Tear(s)',
      Déchiré: 'Torn',
      Excellent: 'Excellent',
      Bon: 'Good',
      Mauvais: 'Bad',
      Fonctionnelle: 'Functional',
      'Non-fonctionnel': 'Non-Functional',
      'Non-fonctionnelle': 'Non-Functional',
      'Non fonctionnelle': 'Non-Functional',
      'Non-fonctionnelles': 'Non-Functional',
      'Non-fonctionnels': 'Non-Functional',
      Fonctionnels: 'Functional',
      Fissures: 'Cracks',
      Fissuré: 'Cracked',
      Fissurée: 'Cracked',
      Fissurés: 'Cracked',
      Fissurées: 'Cracked',
      Craquée: 'Cracked',
      'Impact(s)': 'Impact(s)',
      Enfoncé: 'Bumped',
      Enfoncée: 'Bumped',
      Partiellement_opaque: 'Partially opaque',
      Opaque: 'Opaque',
      Présence_d_humidité: 'Presence of humidity',
      'Impact(s)_présent(s)': 'Impact(s) present',
      Très_légèrement_opaque: 'Very slightly opaque',
      Retouches_peintures: 'Paint touch-up',
      Absence_d_une_visse: 'Absence of a screw',
      Rouille_de_surface: 'Surface rust',
      Bien: 'Good',
      Peu: 'Little',
      Peu_de_taches: 'Few stains',
      Beaucoup_de_taches: 'Lots of stains',
      Peu_de_déchirures: 'Few tears',
      Beaucoup_de_déchirures: 'Lots of tears',
      Aucune_odeur: 'No smell',
      Excellente: 'Excellent',
      Beaucoup: 'A lot',
      Winter: 'Winter',
      Hiver: 'Winter',
      Summer: 'Summer',
      Été: 'Summer',
      Pneu_de_secours: 'Spare tire',
      Kit_de_changement_de_pneu: 'Tire change kit',
      'Kit_anti-crevaison': 'Anti-puncture kit',
      "Crochet_d'attelage": 'Pintle hook',
      Aucun_équipement_présent: 'No equipment present',
      Usé: 'Used',
      'Usé(s)': 'Used',
      Usés: 'Used',
      'Bien_(moins_de_30-60_secondes)': 'Good (less than 30-60 seconds)',
      'Très_bien_(moins_de_10_secondes)': 'Very good (less than 10 seconds)',
      'Non_fonctionnelle_(on_l_attend_encore_depuis_mai)': 'Not functional',
      'Non-applicable': 'N/A',
      Correcte: 'Correct',
      Insuffisante: 'Insufficient',
      Trop_élevée: 'Too high',
      Trop_élevé: 'Too high',
      Présence_d_un_ou_plusieurs_codes: 'Presence of one or more codes',
      'One or more error code': 'One or more error code',
      Aucun: 'None',
      Hiver_clouté: 'Studded winter',
      '4_saisons': '4 seasons',
      Bonne: 'Good',
      Mauvaise: 'Bad',
      Aucun_tapis_inclus: 'No carpet included',
      extra: 'extra',
      extra2: 'extra',
      extra3: 'extra',
      extra4: 'extra',
      Non_présent: 'Not present',
      À_changer: 'To change',
      Non_vérifiable: 'Unverifiable',
      Excellente_propreté_générale: 'Great general cleanliness',
      Bonne_propreté_générale: 'Good general cleanliness',
      Mauvaise_propreté_générale: 'Bad general cleanliness',
      'Droite_non-fonctionnelle': 'Right: non-functional',
      'Droit_non-fonctionnel': 'Right: non-functional',
      'Gauche_non-fonctionnel': 'Left: non-functional',
      'Gauche_non-fonctionnelle': 'Left: non-functional',
      'Les_deux_non-fonctionnels': 'Both: non-functional',
      'Les_deux_non-fonctionnelles': 'Both: non-functional',
      Détérioration_majeure: 'Major deterioration',
      Bruit_de_tôle: 'Sheet metal noise',
      Fuite_mineure: 'Minor leak',
      'Balais_essuie-glace_défectueux': 'Defective wiper blades',
      Lave_glace_défectueux: 'Defective windshield washer',
      'Défaut_mineur_qui_ne_nécessite_pas_d’intervention_immédiate:_aviser_le_garage_lors_du_prochain_entretien_de_contrôler_la_courroie':
        'Minor defect that does not require immediate attention. Tell the garage to check the belt at the next service.',
      Courroie_bruyante: 'Noisy accessory belt',
      'Courroie absente': 'Missing accessory belt',
      '4_roues_motrices': '4-wheel drive',
      '2_roues_motrices': '2-wheel drive',
      Automatique: 'Automatic ',
      Manuelle: 'Manual',
      Automatic: 'Automatic',
      Manual: 'Manual',
      nothingToPlan: 'Good',
      replacementSoon: 'Attention required soon',
      replacementRequired: 'Immediate attention required',
      replacementRequiredTooOld:
        'Immediate attention required (maximum age reached)',
      'Shampoing dégraissant et diagnostic':
        'Degreasing shampoo and diagnostic',
      'Recommandation personnalisée': 'Personalized recommendation',

      // Traductions automatiques du formulaires
      // Besoin de garder les traductions 'pareilles' sinon le texte est traduit en anglais automatiquement
      'Partially opaque': 'Partially opaque',
      Humidity: 'Humidity',
      Cracked: 'Cracked',
      Broken: 'Broken',
      'Scratch(es)': 'Scratch(es)',
      Rust: 'Rust',
      'Stone chip': 'Stone chip',
      Dent: 'Dent',
      'Major Rust': 'Major Rust',
      'Minor Rust': 'Minor Rust',
      Worn: 'Worn',
      Missing: 'Missing',
      Deformed: 'Deformed',
      'Traces of pliers': 'Traces of pliers',
      Holes: 'Holes',
      Dry: 'Dry',
      'Major problem': 'Major problem',
      'Minor problem': 'Minor problem',
      'Driver side': 'Driver side',
      'Passenger side': 'Passenger side',
      'Both side': 'Both side',
      'The seller refuses': 'The seller refuses',
      'Unsafe condition': 'Unsafe condition',
      'Invalid administrative condition': 'Invalid administrative condition',
      Tobacco: 'Tobacco',
      Pets: 'Pets',
      Tears: 'Tears',
      Stains: 'Stains',
      'Minor leak': 'Minor leak',
      'Major leak': 'Major leak',
      'Heatshield noise': 'Heatshield noise',
      'Major deterioration': 'Major deterioration',
      'Windshield washer': 'Windshield washer',
      'Wiper blades': 'Wiper blades',
      Drift: 'Drift',
      'Steering wheel unaligned': 'Steering wheel unaligned',
      'Random behavior': 'Random behavior',
      minor: 'minor',
      major: 'major',
      front: 'front',
      rear: 'rear',
      'Brake maintenance': 'Brake maintenance',
      Replacement: 'Replacement',
      Knocking: 'Knocking',
      Creaking: 'Creaking',
      'Front left': 'Front left',
      'Front right': 'Front right',
      'Not discernible': 'Not discernible',
      Oxidation: 'Oxidation',
      Left: 'Left',
      reft: 'left',
      Right: 'Right',
      right: 'right',
      'Does not heat up': 'Does not heat up',
      Overheats: 'Overheats',
      Hesitation: 'Hesitation',
      Misfire: 'Misfire',
      'Lack of power': 'Lack of power',
      Buzzing: 'Buzzing',
      Rumbling: 'Rumbling',
      'While slowing down': 'Vibration',
      'During acceleration': 'Vibration',
      Hard: 'DiHardfficile',
      Slippage: 'Slippage',
      Latency: 'Latency',
      'High pedal (manual)': 'High pedal (manual)',
      '4 Wheels': '4 Wheels',
      '6 Wheels': '6 Wheels',
      'Normal condition': 'Normal condition',
      'Bad condition': 'Bad condition',
      'Too high': 'Too high',
      'Too low': 'Too low',
      'Strong smell': 'Strong smell',
      None: 'None',
      'N/A': 'N/A',
      Permanent: 'Permanent',
      Validated: 'Validated',
      Anomaly: 'Anomaly',
      'Not verified': 'Not verified',
      Noise: 'Noise',
      Play: 'Play',
      'Hard to turn': 'Hard to turn',
      'Few stains': 'Few stains',
      'Many stains': 'Many stains',
      'Few tears': 'Few tears',
      'Many tears': 'Many tears',
      'Excellent general cleanliness': 'Excellent general cleanliness',
      'Good general cleanliness': 'Good general cleanliness',
      'Bad general cleanliness': 'Bad general cleanliness',
      '2 wheel drive': '2 wheel drive',
      '4 wheel drive': '4 wheel drive',
      'Spare wheel': 'Spare wheel',
      'Spare wheels tools kit': 'Spare wheels tools kit',
      'Puncture repair kit': 'Puncture repair kit',
      'Trailer hook': 'Trailer hook',
      'Heated front seats': 'Heated front seats',
      'Heated back seats': 'Heated back seats',
      Sunroof: 'Sunroof',
      'Cargo net': 'Cargo net',
      'Paint protection film': 'Paint protection film',
      'Roof rack system': 'Roof rack system',
      'Roof box': 'Roof box',
      'Mud guards': 'Mud guards',
      'Side window deflectors': 'Side window deflectors',
      'Hood deflector': 'Hood deflector',
      'Remote starter': 'Remote starter',
      Nothing: 'Nothing',
      Other: 'Other',
      'Degreasing shampoo and diagnostic': 'Degreasing shampoo and diagnostic',
      'Personalized recommendation': 'Personalized recommendation',

      'Partiellement opaque': 'Partially opaque',
      "Présence d'humidité": 'Humidity',
      'Fissure(s)': 'Cracked',
      Brisé: 'Broken',
      Rayures: 'Scratch(es)',
      Rouille: 'Rust',
      'Éclat(s)': 'Stone chip',
      'Enfoncé(e)': 'Dent',
      'Rouille majeure': 'Major Rust',
      'Rouille mineure': 'Minor Rust',
      'Usé(e)(s)': 'Worn',
      'Manquant(e)': 'Missing',
      Déformation: 'Deformed',
      'Traces de pinces': 'Traces of pliers',
      Trous: 'Holes',
      Sèche: 'Dry',
      'Problème majeur': 'Major problem',
      'Problème mineur': 'Minor problem',
      'Droit non-fonctionnel': 'Driver side',
      'Gauche non-fonctionnel': 'Passenger side',
      'Les deux non-fonctionnels': 'Both side',
      'Le vendeur refuse': 'The seller refuses',
      'Condition non sécuritaire': 'Unsafe condition',
      'Condition administrative non conforme':
        'Invalid administrative condition',
      Tabac: 'Tobacco',
      Animaux: 'Pets',
      Déchirures: 'Tears',
      'Tache(s)': 'Stains',
      'Fuite mineure': 'Minor leak',
      'Bruit de tôle': 'Heatshield noise',
      'Fuite majeure': 'Major leak',
      'Détérioration majeure': 'Major deterioration',
      'Lave glace défectueux': 'Windshield washer',
      'Balais essuie-glace défectueux': 'Wiper blades',
      Dérive: 'Drift',
      'Volant non aligné': 'Steering wheel unaligned',
      'Comportement aléatoire': 'Random behavior',
      mineur: 'minor',
      majeur: 'major',
      avant: 'front',
      arrière: 'rear',
      'Entretien de frein': 'Brake maintenance',
      Remplacement: 'Replacement',
      Cognement: 'Knocking',
      Grincement: 'Creaking',
      'Avant gauche': 'Front left',
      'Avant droit': 'Front right',
      'non discernable': 'not discernible',
      Oxydation: 'Oxidation',
      Gauche: 'Left',
      gauche: 'left',
      Droite: 'Right',
      groite: 'right',
      'Ne chauffe pas': 'Does not heat up',
      Surchauffe: 'Overheats',
      Hésitation: 'Hesitation',
      "Raté d'allumage": 'Misfire',
      'Manque de puissance': 'Lack of power',
      Sillement: 'Buzzing',
      Grondement: 'Rumbling',
      'Sur ralenti': 'Vibration',
      'En accélération': 'Vibration',
      Difficile: 'Hard',
      Glissement: 'Slippage',
      Latence: 'Latency',
      'Pédale haute (manuelle)': 'High pedal (manual)',
      '4 roues': '4 Wheels',
      '6 roues': '6 Wheels',
      'Condition normale': 'Normal condition',
      'Mauvaise condition': 'Bad condition',
      'Trop élevé': 'Too high',
      Insuffisant: 'Too low',
      "Présence d'odeur": 'Strong smell',
      Aucune: 'None',
      'Non présent': 'N/A',
      Permanente: 'Permanent',
      'Validé(e)': 'Validated',
      Anomalie: 'Anomaly',
      'Non vérifié(e)': 'Not verified',
      'Non vérifiable': 'Not verifiable',
      Bruit: 'Noise',
      Jeu: 'Play',
      'Point dur': 'Hard to turn',
      'Peu de taches': 'Few stains',
      'Beaucoup de taches': 'Many stains',
      'Peu de déchirures': 'Few tears',
      'Beaucoup de déchirures': 'Many tears',
      'Excellente propreté générale': 'Excellent general cleanliness',
      'Bonne propreté générale': 'Good general cleanliness',
      'Mauvaise propreté générale': 'Bad general cleanliness',
      '2 roues motrices': '2 wheel drive',
      '4 roues motrices': '4 wheel drive',
      'Pneu de secours': 'Spare wheel',
      'Kit de changement de pneu': 'Spare wheels tools kit',
      'Kit anti-crevaison': 'Puncture repair kit',
      "Crochet d'attelage": 'Trailer hook',
      'Sièges chauffants avants': 'Heated front seats',
      'Sièges chauffants arrières ': 'Heated back seats',
      'Toit ouvrant': 'Sunroof',
      'Filet de retenue': 'Cargo net',
      'Film protecteur de carrosserie': 'Paint protection film',
      'Barres de toit': 'Roof rack system',
      'Coffre de toit': 'Roof box',
      'Garde boue': 'Mud guards',
      'Déflecteurs de vitres': 'Side window deflectors',
      'Déflecteur capot': 'Hood deflector',
      'Démarreur à distance': 'Remote starter',
      'Aucun équipement présent': 'Nothing',
      Autre: 'Other',
      'Ventilation non fonctionnelle': 'Nonfunctional ventilation',
      'Ventilation partiellement non fonctionnelle':
        'Partially nonfunctional ventilation',
      "Absence d'air chaud": 'No hot air',
      'Nonfunctional ventilation': 'Nonfunctional ventilation',
      'Partially nonfunctional ventilation':
        'Partially nonfunctional ventilation',
      'No hot air': 'No hot air',
      'Tear(s)': 'Tear(s)',
      'Disjointed seam(s)': 'Disjointed seam(s)',
      'Déchiré(e)': 'Tear(s)',
      'Décousu(e)': 'Disjointed seam(s)',
    },
    types: {
      title: 'Inspection type',
      standard: ['Standard inspection'],
      vanPickupRV: 'Van, Pickup & RV',
      electricPIH: ['Electric & Plug-In Hybrid inspection'],
      premium: 'Premium',
      classicExotic: 'Exotic & Classic',
    },
  },
  form: {
    confirmedUpload: 'Already uploaded',
  },
  business: {
    searchVin: 'Search by VIN',
    searchCity: 'Search by city',
    inspection: 'All Inspections',
    tab: {
      batch: 'Inspections in Batches',
      inspection: 'Single Inspection',
      archivedInspection: 'Archived Inspections',
    },
    field: {
      date: 'Date',
      batchTitle: 'Batch Title',
      type: 'Type',
      inspectionTitle: 'Make | VIN',
      status: 'Status',
      remove: 'Remove',
      location: 'Address',
      city: 'City',
      number: 'No.',
      numberInspection: 'Number of inspections',
      amount: 'Amount (Before Tax)',
      report: 'Report',
      notFinished: 'Pending...',
      action: 'Action',
      archive: 'Archive',
      unarchive: 'Unarchive',
      vin: 'VIN',
      edit: 'Edit',
      selected: 'Selected',
      archive_selected: 'Archive Selected',
      unarchive_selected: 'Unarchive Selected',
      cancel: 'Cancel',
      notFound: 'No matches found.',
    },
    status: {
      waiting: 'Waiting',
      started: 'Started',
      completed: 'Completed',
      ongoing: 'Ongoing',
      accepted: 'Accepted',
      confirmed: 'Confirmed',
      all: 'All',
      programmed: 'Programmed',
      inReview: 'In Review',
    },
    type: {
      psi: 'PSI',
      troublshoot: 'Troubleshoot',
      complete: 'Complete',
    },
    modal: {
      batchTitle: 'Would you like to remove the following batch?',
      inspectionRemoveTitle:
        'Would you like to remove the following inspection?',
      inspectionArchiveTitle:
        'Would you like to archive the following inspection(s)?',
      inspectionUnarchiveTitle:
        'Would you like to unarchive the following inspection(s)?',
      remove: 'Remove',
      cancel: 'Cancel',
      archive: 'Archive',
      unarchive: 'Unarchive',
    },
    snackBar: {
      remove: 'Remove Confirmed!',
      upload: 'Upload Successful!',
      create: 'Batch Created!',
      archive: 'Archived!',
      unarchive: 'Unarchived!',
    },
    navbar: {
      inspection: 'Inspection Status',
      upload: 'Upload Inspection Batch',
      book: 'Book a Single Inspection',
      account: 'Account Status',
      logout: 'Logout',
      invoices: 'Invoices',
      settings: 'Settings',
    },
    upload: 'Upload a New CSV Batch',
    dragAndDrop: {
      title: 'Drag and Drop the CSV File',
      or: 'or',
      browse: 'Browse File',
    },
    form: {
      batch: 'Batch Title',
      batchHint: '*Add custom name to the batch',
      email: 'Business Email',
      emailHint: '*Add contact email to send the batch reports',
      emailError: 'Invalid email',
      submit: 'Submit',
    },
    template: 'Refer to the following CSV Template:',
    inspectionTypeOptions:
      'For <b>inspection type</b> options, please choose between <b>PSI</b>, <b>Complete </b>, or <b>Troubleshoot</b>. ',
    account: {
      title: 'Account Status',
      costSubtitle: 'Cumulative Cost for Current Billing Cycle',
      invoiceSubtitle: 'All Invoices',
    },
    error: {
      noBatchFound: 'No batch found. Please upload a batch.',
      errorBatch: 'Batch issue encountered. Please contact CarInspect',
      noInspectionFound: 'No inspection found. Please upload an inspection.',
      errorInspection:
        'Inspection issue encountered. Please contact CarInspect.',
      amountNotFound: 'Not Found',
      billNotFound: 'No bills found. Please contact CarInspect.',
      wrongFileType: 'Wrong File Type: Require CSV',
      noFile: 'Missing File',
      uploadError: 'We have encountered the errors: ',
    },
    tooltip: 'Cannot be Removed',
    formInspection: {
      bookInspection: 'Book a single inspection',
      firstStep: {
        title: 'Information about the business',
        email: 'Email Address',
      },
      secondStep: {
        title: 'Person/Company to contact to perform inspection',
        name: 'Person/Company',
        address: 'Address',
        city: 'City',
        location: 'Where is the vehicle located?',
        postal: 'Postal Code',
        contact:
          'If you wish to use Logistic Center, please contact your account manager to add new cities.',
        phone: {
          number: 'Phone Number',
          hint: 'Pattern: xxx-xxx-xxxx',
        },
      },
      thirdStep: {
        title: 'Information about the vehicle',
        make: 'Make',
        inspectionType: 'Inspection Type',
        model: 'Model',
        year: 'Year',
        vin: 'VIN Vehicle Identification Number',
        conditionReport: 'Condition Reporting URL',
        vinErrorMessage: 'VIN must be exactly 17 characters long',
        vinNoSpacesErrorMessage: 'VIN cannot contain spaces.',
        fulladdress:
          'Please provide the complete address and choose one of the suggested options.',
        addComponent: 'Add Component',
        toInspect: 'to inspect',
        descriptionIssue: 'Description of the issue',
        component: 'Component',
        subcomponent: 'Subcomponent',
      },
      fourthStep: {
        title: 'Further Information',
        codeError: 'The price may vary depending on the number of error codes.',
        comment: 'Comments',
        conditions:
          'I consent to be billed as per the commercial terms defined in the contract.',
      },
      next: 'Next',
      back: 'Back',
      totalPrice: 'Estimated Price',
      numberOfComponents: 'Number of components',
      submit: 'Book Inspection',
      success: 'Inspection Created!',
      failure: 'Inspection is not created',
    },
    invoices: {
      search: '🔍 Search by VIN number...',
      loading: 'Loading invoices, please wait...',
      download_selected: 'Download Selected',
      invoice_number: 'Invoice Number',
      inspection_type: 'Inspection Type',
      issue_date: 'Issue Date',
      amount: 'Amount (taxes in)',
      actions: 'Actions',
      view: 'View',
      download: 'Download',
    },
  },
  duplicateVin: {
    title: 'Existing VIN',
    archive: 'Archived',
    type: 'Inspection Type',
    nullStatus: 'Not confirmed',
    message:
      'A reservation is already associated with this VIN on your account',
  },
};
