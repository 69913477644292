export class CustomFile {
    file: File;
    hasBeenUploaded: boolean;
    error!: string;
    name!: string;
    url: any;
    size!: number;
    uuid!: string;
    progression!: number;
    mimetype!: string;
    description!: string;

    constructor(fichier: File) {
        this.file = fichier;
        this.hasBeenUploaded = false;
    }
}